import { Calculo } from './../calculo-folha/calculo.enum';
// Angular
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
// RxJS
import { Subject, Subscription, switchMap, take } from 'rxjs'
// Object-Path
import * as objectPath from 'object-path'
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from 'src/app/core/_base/layout'
import { HtmlClassService } from 'src/app/views/theme/html-class.service'
import { LayoutConfig } from 'src/app/core/_config/layout.config'
import { PageConfig } from 'src/app/core/_config/page.config'
// User permissions
import { NgxPermissionsService } from 'ngx-permissions'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService } from 'ngx-bootstrap/datepicker'
import { Retorno } from 'src/app/core/models/retorno'
import { CriptografiaService } from 'src/app/core/shared/services/criptografia.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { Router } from '@angular/router'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import { EventosESocial } from '../eventos-esocial/shared/eventosESocial.model'
import { EventosEsocialService } from '../eventos-esocial/shared/eventos-esocial.service'
import { rotas, verbos } from 'src/app/constantes'
import { EmpresaService } from 'src/app/core/services/empresa.service'
import { RubricasService } from '../rubricas/shared/rubricas.service'
import { LotacaoTributariaService } from '../lotacao-tributaria/shared/lotacao-tributaria.service'
import { ColaboradoresService } from 'src/app/views/pages/folha/funcionarios/shared/colaboradores.service'
import { EsocialEventos } from './esocial-eventos.enum'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Table } from 'primeng/table'

@Component({
  selector: 'app-teste',
  templateUrl: './esocialv2.component.html',
  styleUrls: ['./esocialv2.component.scss']
})
export class Esocialv2Component implements OnInit, OnDestroy {
  @ViewChild('errorModal') errorModal!: TemplateRef<any>
  @ViewChild("filter") filter!: ElementRef;
  modalRef?: BsModalRef
  errorMessage: string = '';
  listaEmpresas: EventosESocial[] = []
  situacoes: any[] = []
  tipos: string[]
  bsConfig: Partial<BsDatepickerConfig>
  minMode: BsDatepickerViewMode = 'month'
  temEmpresa: boolean = false
  relatorioForm: UntypedFormGroup
  estabelecimento: string = ''
  mostrarEventos: boolean = false
  hoje: Date = new Date()
  loadingdt1: boolean = true
  loadingdt2: boolean = true
  situacoesIds: number[] = []
  alterarStatusEnvio: {}
  imediato: boolean = false
  titulo: string = 'E-Socialv2'
  eventos: any[] = []

  // variáveis principais de controle
  dataMovimento: Date
  empresaId: number = -1

  selfLayout = 'default'
  asideSelfDisplay: true
  contentClasses = ''
  contentContainerClasses = ''
  subheaderDisplay = true
  contentExtended: false
  selectedEmpresa: EventosESocial

  private subs = new Subscription();
  private movimentoSubject = new Subject<any>();

  // cabeçalho
  meses: string[] = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]
  anos: any[] = []
  a: number
  d: number
  m: number

  esocialEventos = Object.keys(EsocialEventos).filter(key => isNaN(Number(key)));

  getEventoDescricao(evento: string): string {
    return EsocialEventos[evento]
  }

  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private permissionsService: NgxPermissionsService,

    private eventosEsocialService: EventosEsocialService,
    private cd: ChangeDetectorRef,
    private variaveisContextoService: VariaveisContextoService,
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    protected criptografiaService: CriptografiaService,
    private mensagemService: MensagemService,
    private router: Router,
    private empresaService: EmpresaService,
    private rubricaService: RubricasService,
    private lotacaoService: LotacaoTributariaService,
    private colaboradorService: ColaboradoresService,
    protected modalService: BsModalService
  ) {

    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs)
    this.menuConfigService.loadConfigs()
    this.pageConfigService.loadConfigs(new PageConfig().configs)

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig())

    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
      // reset body class based on global and page level layout config, refer to html-class.service.ts
      document.body.className = ''
      this.htmlClassService.setConfig(layoutConfig)
    })
    subscription.unsubscribe()
  }

  ngOnInit(): void {
    this.titulo = "e-Social"
    const config = this.layoutConfigService.getConfig()
    // Load UI from Layout settings
    this.selfLayout = objectPath.get(config, 'self.layout')
    this.asideSelfDisplay = objectPath.get(config, 'aside.self.display')
    this.subheaderDisplay = objectPath.get(config, 'subheader.display')
    this.contentClasses = this.htmlClassService.getClasses('content', true).toString()
    this.contentContainerClasses = this.htmlClassService.getClasses('content_container', true).toString()
    this.contentExtended = objectPath.get(config, 'content.extended')
    // let the layout type change
    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout')
      })
    })
    subscription.unsubscribe()

    this.localeService.use('pt-BR')
    this.inicializarForm()

    this.bsConfig = Object.assign({}, {
      dateInputFormat: 'MMMM YYYY',
      minMode: this.minMode,
      isAnimated: true,
      adaptivePosition: true
    })

    this.carregarListas()
    this.preencherAnos()
    this.carregaEventosEmpresa()

    this.subs.add(
      this.variaveisContextoService.dataMovimentoAtual.subscribe(dataMovimento => {
        this.dataMovimento = dataMovimento ?? new Date();
        this.a = +this.dataMovimento.getFullYear();
        this.d = +this.dataMovimento.getDate();
        this.m = this.dataMovimento.getMonth();
        this.carregarEmpresas();
      })
    );


    // abre a empresa atual selecionada e com os dados carregados
    this.variaveisContextoService.empresaAtual.pipe(take(1)).subscribe((empresa) => {
      if (empresa !== null && empresa !== undefined) {
        this.selectedEmpresa = empresa
        this.empresaEscolhida(empresa.id)
        this.temEmpresa = true
        this.cd.markForCheck()
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
    this.movimentoSubject.unsubscribe()
    this.permissionsService.flushPermissions()
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, "contains");
  }

  clear(table: Table) {
    table.clear();
    this.filter.nativeElement.value = "";
    this.filter.nativeElement.focus();
  }

  inicializarForm() {
    this.relatorioForm = this.fb.group({
      estabelecimentoId: [0],
      data: [new Date()],
      situacao: [''],
      tipo: ['']
    })
  }

  carregarListas() {
    this.subs.add(this.eventosEsocialService.pegarListaOpcoes().subscribe({
      next: (retorno: Retorno<any>) => {
        const evento = retorno.retorno
        this.situacoes = evento.situacoes;
        this.tipos = evento.tipos;
      },
      error: error => {
        this.mensagemService.showErro(error)
      }
    }))
  }

  getDescricaoPorId(id: number): string {
    const situacao = this.situacoes.find(s => s.id === id);
    return situacao ? situacao.descricao : 'Status não definido';
  }

  getBadgeSeverity(id: number): string {
    switch(id) {
      case 1:
        return 'info'; // Pode ser 'success', 'info', 'warning', 'danger'
      case 2:
      case 4:
      case 7:
        return 'danger';
      case 3:
      case 6:
        return 'warning';
      case 5:
        return 'success';
      default:
        return 'info';
    }
  }

  empresaEscolhida(id: any) {
    this.empresaId = id
    this.carregarEventosMovimento()
  }

  setarDataMovimentoPorAno() {
    console.log('setarDataMovimentoPorAno')
    if (this.a === 1900) {
      this.mensagemService.showAviso('Favor selecionar um ano válido na data movimento!')
      return
    }
    this.d = 1
    this.dataMovimento = new Date(this.a, this.m, this.d);
    this.variaveisContextoService.mudarDataMovimento(this.dataMovimento)
    this.carregarEventosMovimento()
  }

  setarDataMovimentoPorMes(i) {
    console.log('setarDataMovimentoPorMes')
    this.d = 1
    this.m = +i
    this.dataMovimento = new Date(this.a, this.m, this.d);
    this.variaveisContextoService.mudarDataMovimento(this.dataMovimento)
    this.carregarEventosMovimento()
  }

  carregarEventosMovimento() {
    this.movimentoSubject.next(this.empresaId)
  }

  carregaEventosEmpresa() {
    this.loadingdt2 = true
    this.movimentoSubject.pipe(
      switchMap(id => {

        const data = this.dataMovimento
        const mes = data.getMonth() + 1
        const ano = data.getFullYear()

        return this.eventosEsocialService.pegarEventosDaEmpresa(mes, ano, id)
      })).subscribe(retorno => {

        this.eventos = retorno.retorno.eventos
        this.eventos.forEach(evento => {
          evento.descricaoTipo = EsocialEventos['S' + evento.tipo]
        })

        this.listaEmpresas.forEach((evento) => {
          if (evento.vencimentoCertificadoDigital) {
            evento.certificadoVencido = this.isCertificadoVencido(evento.vencimentoCertificadoDigital)
            evento.diasVencimento = this.calcularDiasVencimento(evento.vencimentoCertificadoDigital)
            evento.mostrarEventosEmpresa = false
          }
        }, (error: string) => {
          this.loadingdt2 = false
          this.mensagemService.showErro(error)
        })

        this.mostrarEventos = false
        this.loadingdt2 = false
        this.cd.markForCheck()
      })
  }

  isCertificadoVencido(vencimento: string | number | Date) {
    const vencimentoTimestamp = new Date(vencimento).getTime()
    const agora = this.hoje.getTime()
    return vencimentoTimestamp < agora
  }

  calcularDiasVencimento(vencimento: string | number | Date) {
    const MILISEGUNDOS_POR_DIA = 86400000
    const LIMITE_DIAS = 60
    const vencimentoTimestamp = new Date(vencimento).getTime()
    const agora = this.hoje.getTime()
    const tempo = Math.floor((vencimentoTimestamp - agora) / MILISEGUNDOS_POR_DIA)
    return tempo < LIMITE_DIAS ? tempo : 0
  }

  carregarEmpresas() {
    this.loadingdt1 = true
    const data = this.dataMovimento
    const mes = data.getMonth() + 1
    const ano = data.getFullYear()

    this.subs.add(this.eventosEsocialService.pegarEmpresas(mes, ano).subscribe((retorno: Retorno<EventosESocial[]>) => {
      if (retorno.valido) {
        this.listaEmpresas = retorno.retorno

        this.listaEmpresas.forEach((evento) => {
          const vencimento = new Date(evento.vencimentoCertificadoDigital).getTime()
          const agora = this.hoje.getTime()
          const tempo = Math.floor((vencimento - agora) / 86400000)
          evento.mostrarEventosEmpresa = false
          evento.diasVencimento = 0
          if (evento.vencimentoCertificadoDigital !== null) {
            if (vencimento < agora)
              evento.certificadoVencido = true
            else {
              evento.certificadoVencido = false

              if (tempo < 60) { evento.diasVencimento = tempo }
            }
          }
          return evento
        }, error => {
          this.loadingdt1 = false
          this.mensagemService.showErro(error)
        })

        this.mostrarEventos = false
        this.loadingdt1 = false
        this.cd.markForCheck()
      } else
        this.mensagemService.showErro(retorno.notifications)
    }))
  }

  filtrar(n: number) {
    if (n === 1) {
      this.listaEmpresas = this.listaEmpresas.filter(resp => {
        return resp.eventos.map(evento => {
          evento.situacao.toString().match(n.toString())
        })
      })
    } else if (n === 5) {
      this.listaEmpresas = this.listaEmpresas.filter(resp => {
        return resp.eventos.map(evento => {
          evento.situacao.toString().match(n.toString())
        })
      })
    } else if (n === 3) {
      this.listaEmpresas = this.listaEmpresas.filter(resp => {
        return resp.eventos.map(evento => {
          evento.situacao.toString().match(n.toString())
        })
      })
    } else if (n === 2) {
      this.listaEmpresas = this.listaEmpresas.filter(resp => {
        return resp.eventos.map(evento => {
          evento.situacao.toString().match(n.toString())
        })
      })
    }
  }

  abrirEvento(evento) {
    if (evento.situacao === 1 || evento.situacao === 3 || evento.situacao === 6) {
      this.situacoesIds.push(evento.id)

      this.alterarStatusEnvio = {
        ids: this.situacoesIds,
        imediato: this.imediato = true
      }
      this.subs.add(this.eventosEsocialService.alterarStatus(this.alterarStatusEnvio).subscribe(() => {
        setTimeout(() => {
          this.carregarEmpresas()
          this.mensagemService.showSucesso('Eventos enviados com sucesso!')
        }, 1000)
        this.cd.markForCheck()
      }))
    } else if (evento.situacao === 2 || evento.situacao === 4) {
      setTimeout(() => {
        window.open(this.router.serializeUrl(this.router.createUrlTree(
          ['/#/' + rotas.eventosEsocial2 + '/evento/' + evento.id]
        )).replace('/%23', '/#'), '_blank')
      }, 1000)
      // this.eventosEsocialService
      // .baixarErrosESocial(evento.id)
      // .subscribe(() => {
      //   setTimeout(() => {
      //     const data = this.relatorioForm.get('data').value
      //     this.carregarEventos()
      //     this.mensagemService.showSucesso('Eventos enviados com sucesso!')
      //   }, 1000)

      //   this.cd.markForCheck()
      // })
    } else if (evento.situacao === 5) {
      setTimeout(() => {
        window.open(this.router.serializeUrl(this.router.createUrlTree(
          ['/#/' + rotas.eventosEsocial2 + '/evento/' + evento.id]
        )).replace('/%23', '/#'), '_blank')
      }, 2000)
      return false
    }
    this.imediato = false
  }

  mostrarEventosEmpresa(id) {
    const empresa = this.listaEmpresas.find(empresa => empresa.id === id)
    empresa.mostrarEventosEmpresa = !empresa.mostrarEventosEmpresa
  }

  navegarParaCertificadoDigital(id) {
    if (id === null) {
      window.open(this.router.serializeUrl(this.router.createUrlTree(
        ['/#/certificado-digital/'])).replace('/%23', '/#'), '_blank'
      )
    } else {
      window.open(this.router.serializeUrl(this.router.createUrlTree(
        ['/#/certificado-digital/' + id + '/alterar/'])).replace('/%23', '/#'), '_blank'
      )
    }
  }

  processarESocial() {
    this.loadingdt2 = true
    this.subs.add(this.eventosEsocialService.processarESocial().subscribe({
      next: () => {
        this.carregarEventosMovimento()
        this.loadingdt2 = false
        this.cd.markForCheck()
        this.mensagemService.showSucesso('Eventos processados com sucesso!')
      },
      error: error => {
        this.loadingdt2 = false
        this.mensagemService.showErroApi(error.status)
      }
    }))
  }

  enviarESocial() {
    this.loadingdt2 = true
    this.subs.add(this.eventosEsocialService.enviarESocial(this.selectedEmpresa).subscribe({
      next: () => {
        this.carregarEventosMovimento()
        this.loadingdt2 = false
        this.cd.markForCheck()
        this.mensagemService.showSucesso('Eventos enviados com sucesso!')
      },
      error: error => {
        this.loadingdt2 = false
        this.mensagemService.showErroApi(error.status)
      }
    }))
  }

  consultarESocial() {
    this.loadingdt2 = true
    this.subs.add(this.eventosEsocialService.consultarESocial(this.selectedEmpresa).subscribe({
      next: () => {
        this.carregarEventosMovimento()
        this.loadingdt2 = false
        this.cd.markForCheck()
        this.mensagemService.showSucesso('Retorno de consulta!')
      },
      error: error => {
        this.loadingdt2 = false
        this.mensagemService.showErroApi(error.status)
      }
    }))
  }

  apagarBase() {
    this.subs.add(this.eventosEsocialService.zerarBase(this.selectedEmpresa.id).subscribe({
      next: (retorno) => {
        if (retorno.valido) {
          this.eventos = []
          this.cd.markForCheck()
          this.mensagemService.showSucesso('Base do eSocial apagada com sucesso.')
        }
      },
      error: (error) => this.mensagemService.showErroApi(error)
    }))
  }

  baixarErros() {
    const erros = {}
    this.subs.add(this.eventosEsocialService.baixarErrosESocial(erros).subscribe({
      next: (retorno) => {
        if (retorno.valido) {
          this.carregarEventosMovimento()
          this.cd.markForCheck()
          this.mensagemService.showSucesso('Erros baixados.')
        }
      },
      error: (error) => this.mensagemService.showErroApi(error)
    }))
  }

  baixarErrosLote(id: number) {
    const lote = {}
    this.subs.add(this.eventosEsocialService.baixarErrosLote(id, lote).subscribe({
      next: (retorno) => {
        if (retorno.valido) {
          this.carregarEventosMovimento()
          this.cd.markForCheck()
          this.mensagemService.showSucesso('Erros baixados.')
        }
      },
      error: (error) => this.mensagemService.showErroApi(error)
    }))
  }

  abrirCorrecao(evento) {
    if (evento.empresaId === this.empresaId) {
      if (evento.tipo === 1000)
        window.open(this.router.serializeUrl(
          this.router.createUrlTree(['/#/empresa/' + evento.empresaId + '/' + verbos.alterar])
        ).replace('/%23', '/#'), '_blank')
      else if (evento.tipo === 1005) {
        this.subs.add(this.empresaService.pegarEstabelecimentosEmpresaId(evento.empresaId)
          .subscribe(estabelecimento => {
            const id = estabelecimento.retorno.find(e => e.nome === evento.descricao).id

            window.open(this.router.serializeUrl(
              this.router.createUrlTree(
                ['/#/' + rotas.estabelecimento + '/' + evento.empresaId + '/' + id + '/' + verbos.alterar]
              )
            ).replace('/%23', '/#'), '_blank')
          }))
      } else if (evento.tipo === 1010) {
        this.subs.add(this.rubricaService.carregarLista().subscribe(rubrica => {
          const id = rubrica.retorno.find(r => (
            r.dadosRubrica.dscRubr === evento.descricao &&
            r.empresaId === evento.empresaId
          )).id

          window.open(this.router.serializeUrl(
            this.router.createUrlTree(
              ['/#/' + rotas.rubricas + '/' + id + '/' + verbos.alterar]
            )
          ).replace('/%23', '/#'), '_blank')
        }))
      } else if (evento.tipo === 1020) {
        this.subs.add(this.lotacaoService.carregarLista().subscribe(lotacao => {
          const id = lotacao.retorno.find(l => (
            l.descricao === evento.descricao && l.empresaId === evento.empresaId
          )).id

          window.open(this.router.serializeUrl(
            this.router.createUrlTree(
              ['/#/' + rotas.lotacoesTributarias + '/' + id + '/' + verbos.alterar]
            )
          ).replace('/%23', '/#'), '_blank')
        }))
      } else if (evento.tipo === 1200 || evento.tipo === 1210) {
        const novaData = new Date(evento.dataCompetencia)
        this.variaveisContextoService.mudarDataMovimento(novaData)

        window.open(this.router.serializeUrl(
          this.router.createUrlTree(
            ['/#/' + rotas.calculoFolha + '/' + rotas.calculoMensal]
          )
        ).replace('/%23', '/#'), '_blank')
      } else if (
        evento.tipo === 2190 || evento.tipo === 2200 || evento.tipo === 2205 || evento.tipo === 2206 ||
        evento.tipo === 2230 || evento.tipo === 2300
      ) {
        let id = 0
        this.subs.add(this.colaboradorService.pegarColaboradoresEPreliminares().subscribe((colaborador: Retorno<any>) => {
          if (evento.tipo === 2190) {
            id = colaborador.retorno.preliminares.find(p => p.nome === evento.descricao).id

            window.open(this.router.serializeUrl(
              this.router.createUrlTree(
                ['/#/' + rotas.colaboradorComVinculo + '/preliminar/' + id + '/' + verbos.alterar]
              )
            ).replace('/%23', '/#'), '_blank')
          } else {
            id = colaborador.retorno.funcionarios.find(p => p.nome === evento.descricao).id

            window.open(this.router.serializeUrl(
              this.router.createUrlTree(
                ['/#/' + rotas.colaboradorComVinculo + '/' + id + '/' + verbos.alterar]
              )
            ).replace('/%23', '/#'), '_blank')
          }
        }))
      }
    } else {
      this.subs.add(this.empresaService.pegarEmpresaPorId(evento.empresaId).subscribe(empresa => {
        this.mensagemService.showAviso(
          `Para efetuar essa correção tem que acessar a empresa ${empresa.retorno.nome}!`
        )
      }))
    }
  }

  // cabeçalho
  preencherAnos() {
    let anoAtual: number = new Date().getFullYear() + 2
    this.anos.push(anoAtual)

    for (let i = 0; i < 20; i++) {
      anoAtual--
      this.anos.push({ id: anoAtual, ano: anoAtual })
    }
  }

  openErrorModal(errorMessage: string): void {
    this.errorMessage = errorMessage
    this.modalRef = this.modalService.show(this.errorModal)
  }

  selEvento(evento: any) {
    const eventosMap: { [key: number]: (evento: any) => void } = {
      1000: this.abrir1000.bind(this),
      1005: this.abrir1005.bind(this),
      1010: this.abrir1010.bind(this),
      1020: this.abrir1020.bind(this),
      1200: this.abrir1200.bind(this),
      1210: this.abrir1210.bind(this),
      2200: this.abrir2200.bind(this),
      2190: this.abrir2190.bind(this),
      2205: this.abrir2205.bind(this),
      2206: this.abrir2206.bind(this),
      2300: this.abrir2300.bind(this),
    }
    const handler = eventosMap[evento.tipo]
    if (handler) {
      handler(evento)
    } else {
      console.warn(`No handler found for event type ${evento.tipo}`)
    }
  }

  private abrirEmNovaGuia(path: string) {
    const urlTree = this.router.createUrlTree([path]);
    const url = this.router.serializeUrl(urlTree).replace('/%23', '/#');
    const fullUrl = `${window.location.origin}${url}`;
    window.open(fullUrl, '_blank');
    //console.log(fullUrl);
  }

  abrir1000(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.empresa}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir1005(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.estabelecimento}/${evento.empresaId}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir1010(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.rubricas}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir1020(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.lotacoesTributarias}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir1200(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.calculoFolha}`);
    }
  }

  abrir1210(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.calculoFolha}`);
    }
  }

  abrir2190(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.listaColaboradores}/preliminar/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir2200(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.colaboradorComVinculo}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir2205(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.colaboradorComVinculo}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir2206(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.colaboradorComVinculo}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }

  abrir2300(evento: any) {
    if (evento.id ?? 0 !== 0) {
      this.abrirEmNovaGuia(`/#/${rotas.colaboradorSemVinculo}/${evento.cadastroId}/${verbos.alterar}`);
    }
  }
}
